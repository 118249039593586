define("ember-data-change-tracker/transforms/json", ["exports", "ember-data/transform"], function (exports, _transform) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    serialize: function (value) {
      return value;
    },

    deserialize: function (json) {
      if (typeof json === "string") {
        json = JSON.parse(json);
      }
      return json;
    }
  });
});