define('ember-notify/components/ember-notify', ['exports', 'ember-notify/templates/components/ember-notify', 'ember-notify/message'], function (exports, _emberNotify, _message) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UIkitTheme = exports.SemanticUiTheme = exports.RefillsTheme = exports.BootstrapTheme = exports.Foundation5Theme = exports.FoundationTheme = exports.Theme = undefined;
  exports.default = Ember.Component.extend({
    layout: _emberNotify.default,

    notify: Ember.inject.service(),
    source: Ember.computed.oneWay('notify'),
    messages: null,
    closeAfter: 2500,

    classPrefix: Ember.computed(function () {
      return this.get('defaultClass') || 'ember-notify-default';
    }),
    classNames: ['ember-notify-cn'],
    classNameBindings: ['classPrefix'],
    messageStyle: 'foundation',

    init: function () {
      this._super();
      this.set('messages', Ember.A());
      this.get('source').setTarget(this);

      var style = this.get('messageStyle'),
          theme;
      switch (style) {
        case 'foundation':
          theme = FoundationTheme.create();
          break;
        case 'uikit':
          theme = UIkitTheme.create();
          break;
        case 'foundation-5':
          theme = Foundation5Theme.create();
          break;
        case 'bootstrap':
          theme = BootstrapTheme.create();
          break;
        case 'refills':
          theme = RefillsTheme.create();
          break;
        case 'semantic-ui':
          theme = SemanticUiTheme.create();
          break;
        default:
          throw new Error(`Unknown messageStyle ${style}: options are 'foundation', 'refills', 'bootstrap', and 'semantic-ui'`);
      }
      this.set('theme', theme);
    },
    willDestroyElement: function () {
      this.get('source').setTarget(null);
    },
    show: function (message) {
      if (this.get('isDestroyed')) return;
      if (!(message instanceof _message.default)) {
        message = _message.default.create(message);
      }
      this.get('messages').pushObject(message);
      return message;
    }
  });
  var Theme = exports.Theme = Ember.Object.extend({
    classNamesFor(message) {
      return message.get('type');
    }
  });

  var FoundationTheme = exports.FoundationTheme = Theme.extend({
    classNamesFor(message) {
      var type = message.get('type');
      var classNames = ['callout', type];
      if (type === 'error') classNames.push('alert');
      return classNames.join(' ');
    }
  });

  var Foundation5Theme = exports.Foundation5Theme = Theme.extend({
    classNamesFor(message) {
      var type = message.get('type');
      var classNames = ['alert-box', type];
      if (type === 'error') classNames.push('alert');
      return classNames.join(' ');
    }
  });

  var BootstrapTheme = exports.BootstrapTheme = Theme.extend({
    classNamesFor(message) {
      var type = message.get('type');
      if (type === 'alert' || type === 'error') type = 'danger';
      var classNames = ['alert', `alert-${type}`];
      return classNames.join(' ');
    }
  });

  var RefillsTheme = exports.RefillsTheme = Theme.extend({
    classNamesFor(message) {
      var type = message.get('type');
      var typeMapping = {
        success: 'success',
        alert: 'error',
        error: 'error',
        info: 'notice',
        warning: 'alert'
      };
      return 'flash-' + typeMapping[type];
    }
  });

  var SemanticUiTheme = exports.SemanticUiTheme = Theme.extend({
    classNamesFor(message) {
      var type = message.get('type');
      var typeMapping = {
        success: 'success',
        alert: 'error',
        error: 'error',
        info: 'info',
        warning: 'warning'
      };
      return 'ui message ' + typeMapping[type];
    }
  });

  var UIkitTheme = exports.UIkitTheme = Theme.extend({
    classNamesFor(message) {
      var type = message.get('type');
      var typeMapping = {
        success: 'success',
        alert: 'warning',
        error: 'danger',
        info: 'info',
        warning: 'warning'
      };
      return 'uk-notify-message uk-notify-message-' + typeMapping[type];
    }
  });
});