define('ember-data-change-tracker/transforms/object', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    serialize: function (value) {
      return value && JSON.stringify(value);
    },

    deserialize: function (value) {
      if (Ember.isEmpty(value)) {
        return {};
      }
      if (Ember.typeOf(value) === "object") {
        return value;
      }
      if (Ember.typeOf(value) === 'string') {
        return JSON.parse(value);
      }
    }
  });
});