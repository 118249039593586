define("semantic-ui-ember/components/ui-modal", ["exports", "ember", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-modal"], function (_exports, _ember, _base, _uiModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Component.extend(_base.default, {
    layout: _uiModal.default,
    module: 'modal',
    classNames: ['ui', 'modal'],
    willInitSemantic(settings) {
      this._super(...arguments);
      if (settings.detachable == null) {
        settings.detachable = false;
      }
      if (settings.observeChanges == null) {
        settings.observeChanges = true;
      }
    }
  });
});